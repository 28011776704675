<script setup>
import { signOut_user } from '@/services/auth';
import { useRouter } from 'vue-router';
import { swalError } from '@/helper.js'
import { getAuth } from 'firebase/auth';
import { onMounted, ref } from 'vue';


const router = useRouter()
const auth = getAuth()
const name = ref('')
onMounted(()=>{
  const { displayName } = auth.currentUser
  name.value = displayName
})

async function logOut(){
  let response = await signOut_user()
  if (response.error){
    swalError(response.result, true)
    return
  }
  router.push({name: 'login'})
}
</script>
<template>
  <div class="max-h-[10vh]">
    <div class="flex justify-between items-align-center py-3 px-4">
      <div>
        <span class="w-10 h-10 bg-zinc-300 rounded-full"></span>
        <img src="https://placehold.co/600x400" alt="" class="w-12 h-12 bg-zinc-300 rounded-full">
      </div>
      <p class="my-auto font-semibold tracking-wider">
        Welcome
        <span v-if="name !== ''">
          , {{ name }}
        </span>
        <span v-else>
          Home
        </span>
      </p>
      <div class="my-auto">
        <button type="button" 
          class="btn-primary rounded-full w-10 h-10 pt-1"
          @click="logOut()"
        >
          <vue-feather class="ml-1" type="log-out" size="1.25rem" stroke-width="2"></vue-feather>
        </button>
      </div>
    </div>
  </div>
</template>