// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: "smart-home-8bb08.firebaseapp.com",
  databaseURL: "https://smart-home-8bb08-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "smart-home-8bb08",
  storageBucket: "smart-home-8bb08.appspot.com",
  messagingSenderId: process.env.VUE_APP_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
