import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import HomeView from '../views/HomeView.vue'
import EditDevice from '../views/EditDevice.vue'
import LoginView from '../views/auth/loginView.vue'
import RegisterView from '../views/auth/registerView.vue'
import mainBody from '../views/layout/mainBody.vue'
import ProfileView from '@/views/ProfileView.vue';

const routes = [
  {
    path: '/',
    // name: 'home',
    component: mainBody,
    children:[
      {
        path: '',
        redirect: '/home'
      },
      {
        path: '/edit-device/:id',
        component: EditDevice,
        name: 'edit-device',
        meta: {
          name: 'Edit Device',
          requireAuth: true
        }
      },
      {
        path: '/profile',
        component: ProfileView,
        name: 'profile',
        meta: {
          name: 'Profile',
          requireAuth: true
        }
      },
      {
        path: '/home',
        component: HomeView,
        name: 'home',
        meta: {
          name: 'Home',
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      name: 'Login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      name: 'Register'
    }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const auth = getAuth();
router.beforeEach((to, from, next) => {
  document.title = to.meta.name + ' | Smart Home'
  if (to.name !== 'login' && to.meta.requireAuth){
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("🚀 ~ onAuthStateChanged ~ user:", user)
        next()
      } else {
        next({name: 'login'})
      }
    });  
  }else{
    next()
  }
});


export default router
