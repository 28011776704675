/* eslint-disable */
export const swalError = (text, isHtml = false) =>{
  if (isHtml){
    Swal.fire({title: 'Error', html: text.error, icon: 'error'})
  }else{
    Swal.fire({title: 'Error', text, icon: 'error'})
  }
}

export const swalSuccess = (text, isHtml = false) =>{
  if (isHtml){
    Swal.fire({title: 'Success', html: text.error, icon: 'success'})
  }else{
    Swal.fire({title: 'Success', text, icon: 'success'})
  }
}

export function delayer(ms = 800) {
  return new Promise(resolve => setTimeout(resolve, ms));
}