<script setup>
import { onMounted, ref } from 'vue';
import { auth_google, auth_user, auth_totp } from '@/services/auth.js'
import { getAuth, onAuthStateChanged, TotpMultiFactorGenerator } from 'firebase/auth';
import { delayer, swalError } from '@/helper';
const isLoading = ref(false)
const user = ref({email: '', password: ''})

const auth = getAuth()
onMounted(()=>{
  onAuthStateChanged(auth, (user)=>{
    if (user){
      location.replace('/')
    }
  })
})

async function submit(){
  isLoading.value = true
  let response = await auth_user(user.value)
  console.log("🚀 ~ submit ~ response:", response)
  isLoading.value = false
  if (response.isError){
    swalError(response.error)
    return
  }
  await delayer(700)
  location.replace('/')
}

async function signInGoogle(){
  let response = await auth_google()
  console.log("🚀 ~ signInGoogle ~ response:", response)
  if (response.error){
    if (response.result == 'totp'){
      mfaObj.value = response.data
      console.log("🚀 ~ signInGoogle ~ mfaObj.value:", mfaObj.value)
      showLoginForm.value = false
      return   
    }
    swalError(response.result)
    return
  }
  console.log("🚀 ~ signInGoogle ~ response:", response)
}
const mfaObj = ref()
const totpCode = ref('')
const showLoginForm = ref(true)

const isVeriying = ref(false)
async function submitOTP(){
  isVeriying.value = true
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
    mfaObj.value.mfaResolver.hints[0].uid,
    totpCode.value
  );
  let response = await auth_totp(multiFactorAssertion, mfaObj.value.mfaResolver)
  isVeriying.value = false
  if (response.error){
    swalError(response.result)
    return
  }
}
</script>

<template>
  <div class="p-4 h-screen flex justify-center items-center">
    <div class="card border border-zinc-200 !px-10 !py-10" v-auto-animate>
      <div class="text-center mb-8">
        <h2 class="font-semibold text-2xl mb-4">Welcome back</h2>
        <p>Enter your detail to get sign in to your account</p>
      </div>
      <div v-if="showLoginForm">
        <form @submit.prevent="submit">
          <label for="email">
            Email
          </label>
          <input 
            type="email" 
            name="email" 
            class="form-input"
            v-model="user.email"
            placeholder="Email"
          >
            <!-- required -->
          <label for="password" class="mt-4">
            Password
          </label>
          <input 
            type="password" 
            name="password" 
            class="form-input"
            placeholder="Password"
            v-model="user.password"
          >
            <!-- required -->
  
          <div class="w-full mt-2 flex">
            <div class="w-2/5 pt-2 mr-2">
              <router-link :to="{name: 'register'}" class="w-full btn btn-unknown">
                Register
              </router-link>
            </div>
            <button type="submit" class="w-3/5 btn btn-primary" v-auto-animate>
              <span v-if="!isLoading">
                Log In
              </span>
              <spinnerText v-else msg="Loading" />
            </button>
          </div>
  
        </form>
        <div class="w-full mt-3">
          <button @click="signInGoogle" 
            class="flex items-center w-full btn btn-unknown justify-center"
          >
            <img src="@/assets/google.png" class="w-6 mr-4" alt="">
            Sign in with Google
          </button>
        </div>
      </div>
      <!-- OTP FORM -->
      <div v-else>
        <form @submit.prevent="submitOTP()">
          <label for="totp">
            Code
          </label>
          <input 
            type="text" 
            name="totp" 
            class="form-input"
            v-model="totpCode"
            placeholder="Enter TOTP Code"
          >
          <div class="flex justify-end">
            <button type="submit" class="btn btn-primary">
              <span v-if="!isVeriying">
                Verify
              </span>
              <spinnerText v-else msg="Verifying"/>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>