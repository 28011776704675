<template>
  <div class="card h-[20vh]">
    <div class="animate-pulse">
      <div class="my-2 h-8 rounded-lg bg-zinc-200 w-1/3"></div>
      <div class="my-2 h-4 rounded-lg bg-zinc-200 w-full"></div>
      <div class="my-2 h-4 rounded-lg bg-zinc-200 w-1/2"></div>
      <div class="my-2 h-4 rounded-lg bg-zinc-200 w-full"></div>
      <div class="my-2 h-4 rounded-lg bg-zinc-200 w-1/2"></div>
    </div>
  </div>
</template>