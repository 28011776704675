<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { getDevice, updateDatabase, deviceValRef, updateDeviceData } from '@/services/devices'
import { onValue } from 'firebase/database'
import { useRoute } from 'vue-router';

const device = ref({})
const route = useRoute()
const isError = ref(false)
onMounted(async ()=>{
  let response = await getDevice(route.params.id)
  console.log("🚀 ~ onMounted ~ response:", response)
  if (response.isError){
    isError.value = true
    Swal.fire({
      title: 'Error',
      text: response.error,
      icon: 'error'
    })
    return
  }
  if (response.isEmpty){
    Swal.fire({
      title: 'Error',
      text: response.error,
      icon: 'warning'
    })
  }
  device.value = response
})

function getDeviceValue(item){
  onValue(deviceValRef(item), (snapshot) => {
    updateValue(snapshot.val())
  });
}

function updateValue(val){
  if (device.value.connected_device){
    device.value.connected_device.value = val;
  }
}

async function updateVal(item){
  let x = await updateDatabase(item, !device.value.connected_device.value)
}

async function editName(){
  let response = await updateDeviceData(route.params.id, '/name', device.value.name)
  if (response.isError){
    Swal.fire({
      title: 'Error',
      text: response.error,
      icon: 'error'
    })
    return
  }

  Swal.fire({
    title:'Saved',
    text: 'Data has been updated',
    icon: 'success'
  })
}
</script>

<template>
  <div class="home">
    <div>
      <h2 class="font-semibold text-2xl">Edit Device</h2>
      <!-- Devices -->
      <div class="pt-3">
        <!-- Error -->
        <div v-if="isError" class="card">
          <p>An Error Occur</p>
        </div>
        <!-- Device Information -->
        <div v-else class="card flex my-2">
          <div class="w-full">
            <p class="text-sm text-secondary">
              ID : 
              {{ route.params.id }}
            </p>
            <p>
              <b>Name : </b>
              {{ device.name }}
            </p>
            <p>
              <b>Status : </b>
              Online
            </p>
            
            <!-- Connected Accessory -->
            <div class="my-2">
              <table class="w-full text-sm text-left text-gray-500 bg-slate-500 w-vw">
                <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Option
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b ">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      <div>
                        <p>Relay</p>
                        <span class="text-sm text-secondary">
                          Status : 
                          {{ getDeviceValue(route.params.id) || device.connected_device?.value }}
                        </span>
                      </div>
                    </th>
                    <td class="px-6 py-4">
                      On / Off
                    </td>
                    <td class="px-6 py-4">
                      <button @click="updateVal(route.params.id)" class="btn btn-primary">
                        <span v-if="device.connected_device?.value">
                          On
                        </span>
                        <span v-else>
                          Off
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <form @submit.prevent="editName()">
            <label for="email">
              Name
            </label>
            <input 
              type="text" 
              name="name" 
              class="form-input"
              v-model="device.name"
              placeholer="Device Name"
            >
            <button type="submit" class="btn btn-primary">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scope>
p {
  @apply my-2 text-lg
}
</style>