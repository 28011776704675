/* eslint-disable */
import firebaseApp from '@/firebaseInit.js'
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, get, child, set } from 'firebase/database'

const db = getDatabase();
const auth = getAuth();

export async function getDevices(){
  let data = ''
  const dbRef = ref(getDatabase());
  await get(child(dbRef, `devices`)).then((snapshot) => {
    if (snapshot.exists()) {
      // console.log(snapshot.val());
      data = snapshot.val()
    } else {
      data = {isEmpty: true, error: "No data available"}
    }
  }).catch((error) => {
    data = {isError: true, error: error.message}
    console.error(error);
  });
  return data
}

export function deviceValRef(device_id){
  return ref(db, 'devices/' + device_id + '/connected_device/value');
}

export async function updateDatabase(id, val){
  await set(ref(db, 'devices/' + id + '/connected_device/value'), val)
  .then(() => {
    console.log('success')
  })
  .catch((error) => {
    console.log(error.message)
  });
}

export async function updateDeviceData(id, path, val){
  let data = ''
  await set(ref(db, 'devices/' + id + path), val)
  .then(() => {
    console.log('success')
    data = {isError: false}
  })
  .catch((error) => {
    console.log(error.message)
    data = {isError: true, error: error.message}
  });

  return data
}

export async function getDevice(id){
  let data = ''
  const dbRef = ref(getDatabase());
  await get(child(dbRef, `devices/${id}`)).then((snapshot) => {
    if (snapshot.exists()) {
      // console.log(snapshot.val());
      data = snapshot.val()
    } else {
      data = {isEmpty: true, error: "No data available"}
    }
  }).catch((error) => {
    data = {isError: true, error: error.message}
    console.error(error);
  });
  return data
}