/* eslint-disable */
import firebaseApp from '@/firebaseInit.js'
import { multiFactor,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
  TotpSecret,
  signInWithPopup, GoogleAuthProvider, updatePassword, updateProfile, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
// const email = ''
// const password = ''
const auth = getAuth();

export async function auth_user(user){
  let response = signInWithEmailAndPassword(auth, user.email, user.password)
  .then((userCredential) => {
    return user
  })
  .catch((error) => {
    return {isError: true, error: error.message}
  });
  return response
}

export async function register_user(user){
  let response = createUserWithEmailAndPassword(auth, user.email, user.password)
  .then((userCredential) => {
    return user
  })
  .catch((error) => {
    return error
  });
  return response
}

export async function signOut_user(){
  let response = {error: false}
  await signOut(auth).then(() => {
    // Sign-out successful.
    response.error = false
  }).catch((error) => {
    // An error happened.
    response.error = true
    response.result = error.message
  });
  
  return response
}

export async function update_user(params){
  let response = {error: false}
  await updateProfile(auth.currentUser, 
    params
  ).then(() => {
    // Profile updated!
    response.error = false
  }).catch((error) => {
    // An error occurred
    response.error = true
    response.result = error.message
  });
  return response
}

export async function update_pass(pass){
  let response = {error: false}
  updatePassword(auth.currentUser, pass)
  .then(() => {
    // Update successful.
    response.error = false
  }).catch((error) => {
    // An error ocurred
    response.error = true
    response.result = error.message
  });
}

export async function auth_google(){
  const response = {error: false}
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log("🚀 ~ .then ~ credential:", credential)
    console.log("🚀 ~ .then ~ token:", token)
    console.log("🚀 ~ .then ~ user:", user)
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    const errorCode = error.code;
    response.result = error.message;
    console.log("🚀 ~ .then ~ errorCode:", errorCode)

    if (error.code == "auth/multi-factor-auth-required"){
      const mfaResolver = getMultiFactorResolver(getAuth(), error);
      const enrolledFactors = mfaResolver.hints.map(info => info.displayName);
      console.log("🚀 ~ .then ~ mfaResolver:", mfaResolver.hints[0])
      console.log("🚀 ~ .then ~ enrolledFactors:", enrolledFactors)
      response.result = 'totp'
      response.data = {mfaResolver, enrolledFactors}
      response.error = true
      return response
      // return 
    }

  });
  return response
}

export async function run_totp(){
  // catch error needed
  const multiFactorSession = await multiFactor(auth.currentUser)
                            .getSession();
  const totpSecret = await TotpMultiFactorGenerator.generateSecret(
    multiFactorSession
  );
  return {
    multiFactorSession,
    totpSecret
  }
}

export async function verify_totp(totpSecret, verificationCode){
  let response = {error: false}
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
    totpSecret,
    verificationCode
  );
  await multiFactor(auth.currentUser).enroll(multiFactorAssertion, auth.currentUser.displayName)
  .then(() => {
    // Update successful.
    response.error = false
  }).catch((error) => {
    // An error ocurred
    response.error = true
    response.result = error.message
  });
  return response
}


export async function auth_totp(multiFactorAssertion, mfaResolver){
  let response = {error: false}
  const userCredential = await mfaResolver.resolveSignIn(
    multiFactorAssertion
  ).then(() => {
    // Update successful.
    response.error = false
  }).catch((error) => {
    // An error ocurred
    response.error = true
    response.result = error.message
  });
  return response
}