<script setup>
import { getAuth } from 'firebase/auth';
import { onMounted, ref } from 'vue';
import loadingCard from '@/components/loadingCard'
import { update_user, run_totp, verify_totp, update_pass, auth_google } from '@/services/auth';
import { swalError, swalSuccess } from '@/helper';
import QRious from 'qrious'

const auth = getAuth()
const user = ref({})
const isLoading = ref(true)
const isUpdating = ref(false)
const hasMFA = ref(false)
onMounted(()=>{
  const tempUser = auth.currentUser
  user.value = {...tempUser}
  if (user.value.metadata){
    checkMFA()
    isLoading.value = false
  }
})


function checkMFA(){
  console.log("🚀 ~ checkMFA ~ user.value.reloadUserInfo:", user.value.reloadUserInfo)
  if (user.value.reloadUserInfo.mfaInfo){
    hasMFA.value = true
  }
}

async function submitForm(){
  isUpdating.value = true
  let response = await update_user({...user.value})
  isUpdating.value = false
  if (response.error){
    swalError(response.result)
    return
  }
  swalSuccess('Profile updated')
}

const newPassword = ref('')
const isUpdatingPass = ref(false)
async function submitPass(){
  let response = update_pass(newPassword.value)
  if (response.error){
    swalError(response.result)
    return
  }
  swalSuccess('Password updated')
}

const secretKey = ref('')
async function enableTOTP(){
  // let qrElement = document.getElementById('qrElement')
  let isAuthSuccess = await reauth_user()
  if (!isAuthSuccess){
    return
  }
  let response = await run_totp()
  console.log("🚀 ~ enableTOTP ~ response:", response)
  secretKey.value = response.totpSecret
  const totpUri = response.totpSecret.generateQrCodeUrl(
    auth.currentUser.email,
    "Smart Home"
  );
  var qr = new QRious({
    value: totpUri,
    size: 500
  })
  qrUrl.value = qr.toDataURL()
}

const qrUrl = ref('')
async function reauth_user(){
  let isSuccess = false
  // check auth provider
  let mainAuth = user.value.providerData[0]
  if(mainAuth.providerId == 'google.com'){
    let response = await auth_google()
    if (response.error){
      swalError(response.result)
      return isSuccess
    }
    return isSuccess = true
  }
  swalError('Only login using Google can enable this feature')
  return false
}

const authCode = ref('')
async function verifyTotp(){
  let response = await verify_totp(secretKey.value, authCode.value)
  if (response.error){
    swalError(response.result)
    return
  }
  swalSuccess('TOTP has been configured')
  console.log("🚀 ~ verifyTotp ~ response:", response)
}
</script>

<template>
  <loadingCard v-if="isLoading"/>
  <div v-else class="mt-3">
    <p class="text-2xl font-bold tracking-wider">Account Information</p>
    <div class="card mt-4 !pb-6">
      <div>
        <div class="mb-3">
          <p class="font-semibold">General Information</p>
          <hr class="w-1/12 bg-zinc-900 h-1">
        </div>
        <form @submit.prevent="submitForm()">
          <div>
            <label for="displayName">
              Name
            </label>
            <input 
              type="text" 
              name="displayName" 
              class="form-input"
              placeholder="User display name"
              v-model="user.displayName"
            >
          </div>
          <div>
            <label for="email">
              Email
            </label>
            <input 
              disabled
              type="email" 
              name="email" 
              class="form-input bg-zinc-100"
              placeholder="User email"
              v-model="user.email"
            >
          </div>
          <div class="mt-3 flex justify-end">
            <button type="submit" class="btn btn-primary">
              <span v-if="!isUpdating">
                Update
              </span>
              <spinnerText v-else msg="Updating"/>
            </button>
          </div>
        </form>
      </div>

      <div>
        <div class="mb-3">
          <p class="font-semibold">Update Password</p>
          <hr class="w-1/12 bg-zinc-900 h-1">
        </div>

        <form @submit.prevent="submitPass()">
          <div>
            <label for="newPassword">
              New Password
            </label>
            <input 
              type="password" 
              name="newPassword" 
              class="form-input"
              placeholder="New password"
              v-model="newPassword"
            >
          </div>
          <div class="mt-3 flex justify-end">
            <button type="submit" class="btn btn-primary">
              <span v-if="!isUpdatingPass">
                Update
              </span>
              <spinnerText v-else msg="Updating"/>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="text-center mt-3">
      <p>
        <b>Last login at : </b>
        {{ user.metadata.lastSignInTime }}
      </p>
    </div>
    
    <div class="card mt-3">
      <div class="mb-3">
        <p class="font-semibold">Security Option</p>
        <hr class="w-1/12 bg-zinc-900 h-1">
      </div>

      <div class="w-full">
        <img :src="qrUrl" class="mx-auto w-3/4">
        <div v-if="qrUrl !== ''">
          <form @submit.prevent="verifyTotp()">
            <div>
              <label for="newPassword">
                Code
              </label>
              <input 
                type="text" 
                name="newPassword" 
                class="form-input"
                placeholder="New password"
                v-model="authCode"
              >
            </div>
            <div class="flex justify-end">
              <button type="submit" class="btn btn-primary">
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <button v-if="!hasMFA" @click="enableTOTP()" class="btn btn-primary">
          Enable TOTP
        </button>
        <div v-else>
          <p>TOTP has been enabled</p>
          <!-- <button @click="enableTOTP()" class="btn btn-primary">
            Disable TOTP
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>