<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { register_user } from '@/services/auth.js'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { delayer, swalError } from '@/helper';
const isLoading = ref(false)
const user = ref({email: '', password: ''})

const auth = getAuth()
onMounted(()=>{
  onAuthStateChanged(auth, (user)=>{
    if (user){
      location.replace('/')
    }
  })
})

async function submit(){
  isLoading.value = true
  let response = await register_user(user.value)
  isLoading.value = false
  if (response.isError){
    swalError(response.error)
    return
  }
  delayer()
  location.replace('/')
}
</script>

<template>
  <div class="p-4 h-screen flex justify-center items-center">
    <div class="card border border-zinc-200 !px-10 !py-10">
      <div class="text-center mb-8">
        <h2 class="font-semibold text-2xl mb-4">Register Now</h2>
        <p>Enter your detail to get sign in to your account</p>
      </div>
      <form @submit.prevent="submit">
        <label for="email">
          Email
        </label>
        <input 
          type="email" 
          name="email" 
          class="form-input"
          v-model="user.email"
          >
          <!-- required -->
        <label for="password" class="mt-4">
          Password
        </label>
        <input 
          type="password" 
          name="password" 
          class="form-input"
          v-model="user.password"
          >
          <!-- required -->

        <div class="w-full mt-2 flex">
          <div class="w-2/5 pt-2 mr-2">
            <router-link :to="{name: 'login'}" class="w-full btn btn-unknown">
              Login
            </router-link>
          </div>
          <button type="submit" class="w-3/5 btn btn-primary">
            <span v-if="!isLoading">
              Create account
            </span>
            <span v-else>
              <spinnerText msg="Creating" />
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>