<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import anime from 'animejs/lib/anime.es.js';


const route = useRoute()
const currentPath = computed(()=>route.path)
const menu = [
  {
    icon: 'home',
    name: 'Home',
    path: '/home'
  },
  {
    icon: 'user',
    name: 'Profile',
    path: '/profile'
  },
]

onMounted(()=>{
  anime({
    targets: '#navigation-bar',
    opacity: 1,
    translateY: [100, 0],
    easing: 'linear'
  })
})
</script>

<template>
  <div class="max-h-[10vh] h-[10vh]">
    <div id="navigation-bar" class="opacity-0 fixed bottom-0 w-full bg-white py-3 px-2 rounded-t-lg">
      <div class="flex justify-center">
        <template v-for="item in menu" :key="item.name">
          <router-link id="menu_item" class="basis-1/5 text-center text-zinc-500 pt-3 border-r border-zinc-400" 
            :class="{'!text-amber-600': item.path == currentPath}"
            :to="item.path"
          >
            <vue-feather :type="item.icon"/>
            <p>{{ item.name }}</p>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
#menu_item:last-child{
  @apply border-r-0
}
</style>

