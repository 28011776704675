<script setup>
/* eslint-disable */
import catergoryCard from '@/components/catergoryCard.vue'
import { onMounted, ref } from 'vue';
import { getDevices, deviceValRef, updateDatabase } from '@/services/devices'
import { onValue } from 'firebase/database'
import anime from 'animejs/lib/anime.es.js';

const devices = ref([])
onMounted(async ()=>{
  let response = await getDevices()
  console.log("🚀 ~ onMounted ~ response:", response)
  if (response.isError){
    Swal.fire({
      title: 'Error',
      text: response.error,
      icon: 'error'
    })
    return
  }
  if (response.isEmpty){
    Swal.fire({
      title: 'Error',
      text: response.error,
      icon: 'warning'
    })
  }
  animationOnload()
  devices.value = response
})

function animationOnload(){
  anime({
    targets: '#main-title',
    opacity: 1,
    duration: 1000,
    easing: 'linear',
    complete: function() {
      dropList()
    }
  })
}

function dropList(){
  anime({
    targets: '#content-child',
    opacity: 1,
    translateY: [-10, 0],
    easing: 'linear',
    // duration: 550,
    delay: anime.stagger(500)
  })
}

function getDeviceValue(item){
  console.log("🚀 ~ getDeviceValue ~ item:", item)
  onValue(deviceValRef(item), (snapshot) => {
    console.log('in listener');
    console.log(snapshot.val());
    updateValue(item, snapshot.val())
  });
}

function updateValue(item, val){
  devices.value[item].connected_device.value = val
}

async function updateVal(item){
 let x = await updateDatabase(item, !devices.value[item].connected_device.value)
 console.log("🚀 ~ updateVal ~ x:", x)
}
</script>

<template>
  <div class="home">
    <div>
      <h2 id="main-title" class="opacity-0 font-semibold text-2xl">Smart Devices</h2>
      <!-- Devices -->
      <div class="pt-3">
        <div id="content-child" class=" opacity-0 card flex my-2" v-for="(item, i) in devices" :key="i">
          <div class="flex">
            <div class="w-2/5">
              <img src="https://placehold.co/600x400" alt="">
            </div>
            <div class="px-3">
              <p class="font-semibold text-lg tracking-wide">
                {{ item.name }}
              </p>
              <p>Connected Device : {{ item.connected_device?.type }}</p>
              <p>
                Status : 
                {{ getDeviceValue(i) || item.connected_device?.value }}
              </p>
              <div>
                <button class="!py-1 btn btn-primary me-2"
                  @click="updateVal(i)"
                >
                  <span v-if="item.connected_device?.value">
                    Off
                  </span>
                  <span v-else>
                    On
                  </span>
                </button>
                <router-link class="btn btn-unknown"
                  :to="{name: 'edit-device', params: {id: i}}"
                >
                  <span>
                    Edit
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>